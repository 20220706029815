import React from "react";
import { FaInfoCircle } from "react-icons/fa";
import { Link } from "gatsby";

import { SeoComponent } from "../components/SeoComponent";
import Form from "../components/Form";
import { useAllMatches } from "../hooks/useAllMatches";
import { useStandings } from "../hooks/useStandings";
import { logosForFullName } from "../util/logos";
import { getMatchURL, getTeamURL } from "../util/urlUtil";

const numberOfTeamsToRelegate = 4;
const totalNumberOfTeams = 19;

const generateTeamMap = (standings, allMatches) => {
  const today = new Date();
  const nextMatches = allMatches.filter(
    (match) => new Date(match.date) > today
  );
  const pastMatches = allMatches
    .filter((match) => new Date(match.date) < today)
    .sort((a, b) => b.date - a.date);
  const teamsMap = {};
  standings.forEach((standing) => {
    teamsMap[standing.fullName] = {
      past: [],
      nextMatch: undefined,
      slug: standing.slug,
      fullName: standing.fullName,
    };
  });
  Object.keys(teamsMap).forEach((team) => {
    let matchCount = 0;
    let ind = 0;
    const maxPastMatches = 5;
    while (matchCount < maxPastMatches && ind < pastMatches.length) {
      if (
        (pastMatches[ind].away.fullName === team ||
          pastMatches[ind].home.fullName === team) &&
        pastMatches[ind].homeScore !== null
      ) {
        teamsMap[team].past.push(pastMatches[ind]);
        matchCount++;
      }
      ind++;
    }

    ind = 0;

    while (ind < nextMatches.length) {
      if (
        nextMatches[ind].away.fullName === team ||
        nextMatches[ind].home.fullName === team
      ) {
        teamsMap[team].nextMatch = nextMatches[ind];
        break;
      }
      ind++;
    }
  });

  return teamsMap;
};

const getRankStyle = (rank) => {
  let rankStyle = "text-black bg-white";
  if (rank === 1 || rank === 2) {
    rankStyle =
      "border border-solid rounded-sm border-black h-7 bg-sky-800 text-white";
  } else if (rank === 3) {
    rankStyle =
      "border border-solid rounded-sm border-black h-7 bg-red-800 text-white";
  } else if (rank === 4) {
    rankStyle =
      "border border-solid rounded-sm border-black h-7 bg-yellow-500 text-white";
  } else if (
    rank > totalNumberOfTeams - numberOfTeamsToRelegate &&
    rank <= totalNumberOfTeams
  ) {
    rankStyle =
      "border border-solid rounded-sm border-black h-7 bg-red-500 text-white";
  }
  return rankStyle;
};

const NextMatchInfo = ({ teamFullName, nextMatch }) => {
  if (!nextMatch) {
    return <div>-</div>;
  }

  const playingAtHome = nextMatch.home.fullName === teamFullName;
  const opponentTeamFullName = playingAtHome
    ? nextMatch.away.fullName
    : nextMatch.home.fullName;
  const matchUrl = playingAtHome
    ? getMatchURL(teamFullName, opponentTeamFullName)
    : getMatchURL(opponentTeamFullName, teamFullName);
  return (
    <Link title={opponentTeamFullName} to={matchUrl}>
      <img
        width="30px"
        height="30px"
        src={logosForFullName[opponentTeamFullName]}
        alt={opponentTeamFullName}
      />
    </Link>
  );
};
const Standing = () => {
  const { allMatches } = useAllMatches();
  const standings = useStandings();
  const teamsMap = generateTeamMap(standings, allMatches);
  const gameAvaragePointsCellStyle =
    "text-xs justify-center mx-1 flex w-6 sm:w-12 sm:text-sm md:text-base md:w-12 md:shrink-0 md:grow-0";
  const teamNameStyle =
    "flex items-center grow w-20 text-xs sm:text-sm md:w-40 md:mr-4 xl:shrink-0";
  const numberStyle =
    "shrink-0 grow-0 flex justify-center items-center w-6 ml-4 mr-3 font-bold text-xs sm:text-sm md:w-8 md:text-base md:mr-1";
  const formClass = "hidden xl:flex xl:w-60 justify-center";
  const nextMatchStlye =
    "hidden lg:flex lg:basis-20 lg:justify-center lg:mx-8 lg:text-center";
  const legendStyle =
    "border border-solid rounded-sm border-black h-5 w-5 mr-2";
  return (
    <>
      <div className="flex flex-col items-center w-full dark:text-white dark:bg-gray-900">
        <h1 className="text-pink-500 font-bold lg:mt-0 mt-4">
          Süper Lig Puan Durumu
        </h1>
        <div className="flex flex-col border border-solid m-4 ">
          <div className="flex items-center bg-gray-50 h-16 dark:text-white dark:bg-gray-900">
            <div className={numberStyle}>#</div>
            <div className={`${teamNameStyle} pl-4`}>Takım</div>
            <div className={gameAvaragePointsCellStyle}>O</div>
            <div className={gameAvaragePointsCellStyle}>G</div>
            <div className={gameAvaragePointsCellStyle}>B</div>
            <div className={gameAvaragePointsCellStyle}>M</div>
            <div className={gameAvaragePointsCellStyle}>A</div>
            <div className={gameAvaragePointsCellStyle}>Y</div>
            <div className={gameAvaragePointsCellStyle}>Av</div>
            <div className={gameAvaragePointsCellStyle}>P</div>
            <div className={formClass}>Form</div>
            <div className={nextMatchStlye}>Sonraki Maç</div>
          </div>
          {standings.map((standing) => (
            <div
              className="flex items-center w-full border border-solid h-12"
              key={standing.rank}
            >
              <div className={`${numberStyle} ${getRankStyle(standing.rank)}`}>
                {standing.rank}.
              </div>
              <Link
                className={teamNameStyle}
                to={getTeamURL(standing.fullName)}
              >
                <div className="flex shrink-0 sm:basis-8 md:mr-1">
                  <img
                    width="30px"
                    height="30px"
                    src={logosForFullName[standing.fullName]}
                    alt={standing.fullName}
                  />
                </div>
                <div className="hidden md:flex">{standing.fullName}</div>
                <div className="w-full md:hidden ml-1">
                  {standing.shortName}
                </div>
              </Link>
              <div className={gameAvaragePointsCellStyle}>
                {standing.played}
              </div>
              <div className={gameAvaragePointsCellStyle}>{standing.win}</div>
              <div className={gameAvaragePointsCellStyle}>{standing.draw}</div>
              <div className={gameAvaragePointsCellStyle}>{standing.lose}</div>
              <div className={gameAvaragePointsCellStyle}>{standing.for}</div>
              <div className={gameAvaragePointsCellStyle}>
                {standing.against}
              </div>
              <div className={gameAvaragePointsCellStyle}>
                {standing.goalsDiff > 0 ? "+" : ""}
                {standing.goalsDiff}
              </div>
              <div className={`${gameAvaragePointsCellStyle} font-bold`}>
                {standing.points}
              </div>
              <div className={formClass}>
                <Form
                  pastMatches={teamsMap[standing.fullName].past}
                  fullName={standing.fullName}
                />
              </div>
              <div className={nextMatchStlye}>
                <NextMatchInfo
                  nextMatch={teamsMap[standing.fullName].nextMatch}
                  teamFullName={standing.fullName}
                />
              </div>
            </div>
          ))}
          <div className="w-full flex pl-4 flex-col gap-2 text-xs my-3">
            <div className="flex flex-row">
              <div className={`${legendStyle}  bg-sky-800`}></div>
              <span>Kupaya katılım - Şampiyonlar Ligi (Elemeler)</span>
            </div>
            <div className="flex flex-row">
              <div className={`${legendStyle}  bg-red-800`}></div>
              <span>Kupaya katılım - Avrupa Ligi (Elemeler)</span>
            </div>
            <div className="flex flex-row">
              <div className={`${legendStyle}  bg-yellow-500`}></div>
              <span>Kupaya katılım - Avrupa Konferans Ligi (Elemeler)</span>
            </div>
            <div className="flex flex-row">
              <div className={`${legendStyle}  bg-red-500`}></div>
              <span>Küme Düşme - TFF 1. Lig</span>
            </div>
            <div className="flex flex-row">
              <FaInfoCircle
                className="mr-2 h-5 w-5"
                title="Federasyon Kararı"
              />
              <strong>Adana Demirspor -6 puan</strong>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Standing;

export const Head = () => (
  <SeoComponent
    title="Süper Lig Puan Durumu"
    description="Türkiye Futbol Süper Ligi 2024-25 Sezonu Puan Durumu"
    keywords="puan durumu, puan tablosu, süper lig puan durumu, Süper lig, Super lig, Süperlig, Superlig, ensuperlig, ensüperlig, fikstür,
    Futbol, Maçlar, Maclar, Spor, Adana Demirspor, Alanyaspor, Antalyaspor, Başakşehir, Basaksehir, Beşiktaş, Besiktas, Bodrumspor, Eyüpspor,
    Eyupspor, Galatasaray, Fenerbahçe, Gaziantep, Gaziantep FK, Goztepe, Göztepe, Hatayspor, Kasımpaşa, Kasimpasa, Konyaspor,
    Rizespor, Sivasspor, Trabzonspor"
  />
);
